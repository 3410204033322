
.HomeScreen_Root {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}

.HomeScreen_Nav {
    width: 100%;
    height: 100%;
}

.HomeScreen_Promo {
    top: 0;
    height: 100%;
    flex: 1;
    overflow: hidden;
}

.HomeScreen_Promo img {
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
}

.HomeScreen_Nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Nav_Button {
    padding: 15px 0;
    display: flex;
    align-items: center;

    border: none;
    outline: none;
    appearance: none;

    background-color: transparent;
    transition: background-color .2s ease, padding .2s linear;
    cursor: pointer;
}

.Nav_Button:hover {
    padding: 21px 0;
    background-color: rgba(26, 26, 26, 0.13);
}

.Nav_ButtonBlue {
    padding: 21px 94px;
    background-color: #658ea1;
    margin-right: 1.3em;

    /*border-top-right-radius: 12px;*/
    /*border-bottom-right-radius: 12px;*/

    transition: padding .2s ease;
}

.Nav_Button:hover .Nav_ButtonBlue {
    padding: 26px 104px;
}

.HomeScreen_VideoRoot,
.HomeScreen_VideoBackdrop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.HomeScreen_VideoRoot {
    background-color: #658ea1;
    transition: transform 0.56s ease-in-out;

    transform: translateX(100vw);
    animation: hiddenVideoPlayer .1s;
    animation-delay: .9s;
    animation-fill-mode: forwards;
}

.HomeScreen_VideoRoot.active {
    transform: translateX(0%);
    opacity: 1;
    animation: none;
}

@keyframes hiddenVideoPlayer {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.HomeScreen_VideoBackdrop {
    position: relative;
    transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out;
    /*transition-delay: 0.3s;*/
    /*background-color: black;*/
    background-color: white;
    transform: translateX(100vw);
}

.HomeScreen_VideoRoot.active .HomeScreen_VideoBackdrop {
    transform: translateX(0%);
    background-color: white;
    transition-delay: 0s;
}

.HomeScreen_VideoContainer {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
}

.requestedVideoPlayback_Root {
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 1;

    transition: opacity .2s ease-in-out;
    transition-delay: .3s;
    will-change: opacity;

    background-color: white;

}

.requestedVideoPlayback_Root.hidden {
    opacity: 0;
    background-color: black;
    transition-duration: .2s;
    transition-delay: 0s;
}


.requestedVideoPlayback {

    position: absolute;

    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 1;

    transition: opacity .2s ease-in-out;
    transition-delay: 0s;
    will-change: opacity;
}

.requestedVideoPlayback.hidden {
    opacity: 0;
    transition-duration: 0s;
    visibility: hidden;
}




/*.requestedVideoPlayback.hidden {*/
/*    transition-duration: 0s;*/
/*}*/

.HomeScreen_VideoRoot.active .requestedVideoPlayback {
    opacity: 1;
    transition-delay: .5s;
}

.HomeScreen_VideoGoBack {
    top: 0;
    left: 0;

    padding:  .8em .75em .75em .85em;
    border-radius: 235px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid rgba(91, 91, 91, 0.35);
    outline: none;
    appearance: none;

    background-color: rgba(63, 62, 62, 0.44);
    backdrop-filter: blur(5px);
    transition: opacity .2s linear;
    cursor: pointer;
    opacity: 0;
}

.HomeScreen_VideoGoBack img {
    filter: invert(1);
}

.active .HomeScreen_VideoGoBack {
    opacity: 1;
    transition-delay: 1s;
}

.HomeScreen_Overlay {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0.6em 1em 1em 0.6em;
}

header {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    padding-left: 1em;

    background-color: #658ea1;
}


@media only screen and (max-width: 1629px) {
    .Nav_Button img {
        height: 42px;
    }

}



@media only screen and (max-width: 1400px) {

    .HomeScreen_Root {
        position: relative;
        display: flex;
        width: 100%;
        height: 110%;
    }

    .HomeScreen_Promo {
        top: 0;
        height: 100%;
        flex: 1;
        overflow: hidden;

        display: none;
    }


    .Nav_Button {
        padding: 15px 0;
        /*display: flex;*/
        /*flex-direction: column;*/
        align-items: baseline;
    }

    .Nav_Button:hover {
        padding: 21px 0;
        background-color: rgba(26, 26, 26, 0.13);
    }

    .Nav_ButtonBlue {
        padding: 14px 5px;
        background-color: #658ea1;
        margin-right: 0;
        align-items: center;
        /*width: 100%;*/
        /*flex: 1;*/
        flex: 0;
        /*border-top-right-radius: 12px;*/
        /*border-bottom-right-radius: 12px;*/

        transition: padding .2s ease;
    }

    .Nav_Button:hover .Nav_ButtonBlue {
        padding: 14px 12px;
    }

    .Nav_Button img {
        height: 38px;
        margin-left: 12px;
    }

    header {
        display: block;
    }
}

@media only screen and (max-width: 750px) {
    .Nav_Button  {
        align-items: center;
        height: 31px;
        /*margin-left: 12px;*/
    }

    .Nav_Button img {
        height: 31px;
        margin-left: 12px;
    }

}

@media only screen and (max-width: 500px) {
    .Nav_Button img {
        height: 21px;
        margin-left: 12px;
    }

}

@media only screen and (max-width: 400px) {
    .Nav_Button img {
        height: 14px;
        margin-left: 12px;
    }
}