* {
    margin: 0;
    padding: 0;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

body {
    overflow-x: hidden;
}

/*.page {*/
/*    overflow-x: hidden;*/
/*    overflow-y: hidden;*/
/*}*/


/*.page {*/
/*    overflow: hidden;*/
/*}*/

video {
    border: none;
    outline: none;
}