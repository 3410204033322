

.InactivityVideoLoop_Root {
    /*position: fixed;*/
    /*top: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*left: 0;*/

    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

body {
    overflow: hidden;
}

.InactivityVideoLoop_Inner {
    position: relative;
    width: 100%;
    height: 100%;

    background-color: white;

}

.InactivityVideoLoop_Video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: white;
    pointer-events: none;
}

.InactivityVideoLoop_ControlsRoot {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 1em 1em;

    display: flex;
    justify-content: center;
    color: white;
    width: 100%;

    z-index: 99;

}

.InactivityVideoLoop_Controls {
    padding: .7em 2em;
    border-radius: 24px;

    text-align: center;

    background-color: rgba(0, 0, 0, 0.45);
    backdrop-filter: blur(25px);
}