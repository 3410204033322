.container {
    position: relative;
}

.page {
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100vw;
    height: 100vh;
    transition: opacity 800ms ease-in-out, transform 800ms ease-out;
    transition: opacity 400ms ease-out, transform 400ms ease-out;
    will-change: opacity;
}

.page-enter {
    opacity: .3;
    transform: scale(1.1) translateY(000px);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0px);
}

.page-exit {
    opacity: 1;
    transform: scale(1) translateY(000px);
}

.page-exit-active {
    opacity: 0;
    transform: scale(1) translateY(000px);;

    transition: opacity 400ms ease-out, transform 400ms ease-out;
}